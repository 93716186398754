import React from "react";

const SkeletonPage = () => {
  return (
    <div className="flex w-full justify-center mt-3">
      <div role="status">
        <span className="sr-only">Loading...</span>
      </div>
      {/* <div className="flex mt-2">
        <Skeleton className="h-12 w-12 rounded-full " />
        <div className="ml-2 space-y-2">
          <Skeleton className="h-4 mb-3 mt-1 w-[250px]" />
          <Skeleton className="h-4 w-[200px]" />
        </div>
      </div>
      <div className="flex mt-5">
        <Skeleton className="h-12 w-12 rounded-full " />
        <div className="ml-2 space-y-2">
          <Skeleton className="h-4 mb-3 mt-1 w-[250px]" />
          <Skeleton className="h-4 w-[200px]" />
        </div>
      </div> */}
    </div>
  );
};

export default SkeletonPage;
