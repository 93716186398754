import React, { useEffect, useState } from "react";
import "./App.css";
import "./global.css";
import Puckview from "./Puckview";

function App() {
  const [id, setId] = useState<string>("");
  console.log("🚀 ~ App ~ id:", id);

  useEffect(() => {
    const urlId = window.location.pathname.split("/").pop();
    if (urlId) setId(urlId);
  }, []);

  return (
    <div className="App">
      <Puckview params={{ id }}></Puckview>
    </div>
  );
}

export default App;
