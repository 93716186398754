import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export const formatValue = (
  value: string | number,
  prefix?: string,
  suffix?: string,
  dontFormat?: boolean
) => {
  let stringValue = value.toString();
  stringValue = stringValue.replace(/[^\d.]/g, "");
  let [whole, decimal] = stringValue.split(".");
  whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const formattedValue = decimal ? `${whole}.${decimal}` : whole;
  if (dontFormat) {
    return `${prefix || ""}${value}${suffix || ""}`;
  }
  return `${prefix || ""}${formattedValue}${suffix || ""}`;
};
export function convertToIcoAndSetFavicon(base64Image: any) {
  const img = new Image();
  img.onload = () => {
    const canvas = document.createElement("canvas");
    canvas.width = 16; // You can adjust the size
    canvas.height = 16;
    const ctx = canvas.getContext("2d"); ///@ts-ignore

    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((icoBlob) => {
      ///@ts-ignore

      const url = URL.createObjectURL(icoBlob); ///@ts-ignore

      document.querySelector('link[rel="icon"]').href = url;
    }, "image/x-icon");
  };
  img.src = base64Image;
}
export const toCamelCase = (str: string): string => {
  return str
    .split(" ")
    .map((word, index) =>
      index === 0
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join("");
};
export const vertical = {
  type: "VerticalSpace",
  props: {
    size: "24px",
    id: `vert-${Math.random().toString(36).substr(2, 9)}-${Date.now()}`,
  },
};

export const sliderProps = {
  maxWidth: "100%",
  inputBgColor: "#ffffff",
  textColor: "#000000",
  inputRadius: "4px",
  inputBorderWidth: "1px",
  inputBorderColor: "#cccccc",
  inputType: "slider",
  max: "100",
  defaultInputValue: "30",
  step: "1",
};
export const inputFormSubmit = {
  type: "InputFormSubmit",
  props: {
    label: "Submit",
    align: "center",
    loading: false,
    id: `InputFormSubmit-${Math.random().toString(36).substr(2, 9)}`,
  },
};

export const root = {
  title: "Toolcrafter Calculator",
  background: "#cfcefe",
};

export function deepClone(obj: any) {
  return JSON.parse(JSON.stringify(obj));
}

export type INContent = Array<{
  type: string;
  props:
    | { size: string; id: string }
    | {
        id: string;
        maxWidth: string;
        inputBgColor: string;
        textColor: string;
        inputRadius: string;
        inputBorderWidth: string;
        inputBorderColor: string;
        inputType: string;
        max: string;
        defaultInputValue: string;
        step: string;
      };
}>;
export type INContentOutputs = Array<{
  type: string;
  props:
    | { size: string; id: string }
    | {
        id: string;
        maxWidth: string;
        inputBgColor: string;
        textColor: string;
        inputRadius: string;
        inputBorderWidth: string;
        inputBorderColor: string;
        inputType: string;
        max: string;
        defaultInputValue: string;
        step: string;
      };
}>;
