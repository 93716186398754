import { useEffect, useState } from "react";

import SkeletonPage from "@/components/SkeletonPage";
import { Form } from "@/components/ui/form";
import { Render } from "@measured/puck";
import "@measured/puck/puck.css";
import { customInputFormConfig } from "./InputFormSubmit";

export default function PuckConfig({
  resolvedData,
  renderConfig,
  onSubmit,
  id,
  form,
  isLoading,
  register,
}: {
  register: any;
  form: any;
  isLoading: any;
  onSubmit: any;
  resolvedData: any;
  id: any;
  renderConfig: any;
}) {
  const [useResolved, setResolved] = useState(null);

  useEffect(() => {
    const runWait = async () => {
      const res = await resolvedData;
      if (!useResolved) {
        setResolved(res);
      }
    };
    void runWait();
  }, [resolvedData, useResolved]);

  if (!useResolved) return <SkeletonPage />;

  const formRenderConfig = {
    ...renderConfig,

    components: {
      ...renderConfig.components,
      InputFormSubmit: customInputFormConfig({ loading: isLoading }),
    },
  };

  return (
    <Form {...form} register={register}>
      <form onSubmit={onSubmit} className="w-full space-y-3">
        <Render config={formRenderConfig} data={useResolved} />
      </form>
    </Form>
  );
}
