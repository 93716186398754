/* eslint-disable  */
import { useEffect } from "react";
import { getData } from "./fetchDataClient";
import { type ITool, useStore } from "./store";
import { setData } from "./setDataClient";

function useToolData(id: null | string, isViewer?: boolean): ITool | undefined {
  //@ts-ignore
  const tool = useStore((state: { selectedTool: any }) => state.selectedTool);

  useEffect(() => {
    let isActive = true;
    const fetchDataAsync = async () => {
      try {
        const url = `/tools/view/${id}`;
        const result = await getData(url);

        // const url = `/tools/${id}`;
        // let result;
        // result = await getData(url);

        if (isActive)
   
        useStore.setState({
          selectedTool: {
            ...result.tool,
            inputs: result.inputs,
            ouputs: result.outputs,
          },
          puckData: result.tool.designerData,
        });
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      fetchDataAsync();
    }

    return () => {
      useStore.setState({ selectedTool: undefined });
      isActive = false;
    };
  }, [id]);

  return tool;
}

export default useToolData;
