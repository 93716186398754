import { cn } from '@/lib/utils'
import { FilePlusIcon } from '@radix-ui/react-icons'
import * as React from 'react'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  inputFull?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, inputFull, ...props }, ref) => {
    const fileInputClass = type === 'file' ? 'file-input-custom' : ''

    return (
      <div className="relative w-full">
        {type === 'file' && !inputFull && (
          <div className=" cursor-pointer bg-black p-1 rounded-full absolute right-3 top-2">
            <FilePlusIcon className="w-4 h-4 text-white" />
          </div>
        )}
        <input
          type={type}
          className={cn(
            'flex cursor-pointer h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            fileInputClass,
            className
          )}
          ref={ref}
          {...props}
        />
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
