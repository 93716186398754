import React from 'react'
import { type ComponentConfig, DropZone } from '@measured/puck'
import styles from './styles.module.css'
import getClassNameFactory from '@/lib/getClassNameFactory'
import { Section } from '../../../components/Section'

const getClassName = getClassNameFactory('Columns', styles)

export interface ColumnsProps {
  distribution: 'auto' | 'manual'
  columns: Array<{
    span?: number
  }>
}

export const Columns: ComponentConfig<ColumnsProps> = {
  fields: {
    distribution: {
      type: 'radio',
      options: [
        {
          value: 'auto',
          label: 'Auto'
        },
        {
          value: 'manual',
          label: 'Manual'
        }
      ]
    },
    columns: {
      type: 'array',
      getItemSummary: (col, id) =>
        `Column ${id ?? 1 + 1}, span ${
          col.span ? Math.max(Math.min(col.span, 12), 1) : 'auto'
        }`,
      arrayFields: {
        span: {
          label: 'Span (1-12)',
          type: 'number'
        }
      }
    }
  },
  defaultProps: {
    distribution: 'auto',
    columns: [{}, {}]
  },
  render: ({ columns, distribution }) => {
    return (
      <Section>
        <div
          className={getClassName()}
          style={{
            gridTemplateColumns:
              distribution === 'manual'
                ? 'repeat(12, 1fr)'
                : `repeat(${columns.length}, 1fr)`
          }}
        >
          {columns.map(({ span }, idx) => (
            <div
              key={idx}
              style={{
                gridColumn:
                  span && distribution === 'manual'
                    ? `span ${Math.max(Math.min(span, 12), 1)}`
                    : ''
              }}
            >
              <DropZone zone={`column-${idx}`} />
            </div>
          ))}
        </div>
      </Section>
    )
  }
}
