import { Section } from "../../../components/Section";
import { ImageSelect } from "./ImageSelect";

export interface ImageProps {
  width: number;
  height: number;
  imageOptions: any;
  images: any;
}

export const Image = ({ images }: any) => ({
  fields: {
    imageUrl: {
      type: "text",
    },
    imageOptions: {
      type: "custom",
      render: ({ name, onChange, value }: any) => {
        return (
          <ImageSelect images={images} onChange={onChange} value={value} />
        );
      },
    },
    width: {
      type: "number",
    },
    height: {
      type: "number",
    },
  },
  resolveData: async ({ props }, { changed }) => {
    // Remove read-only from the title field if `data` is empty
    if (!props.imageOptions) return { props, readOnly: { imageUrl: false } };

    // Don't query unless `data` has changed since resolveData was last run
    if (!changed.imageOptions) return { props };

    const width = props?.width;
    const height = props?.height;

    let imageUrl = `${process.env.REACT_APP_BASE_URL}${props?.imageOptions.image}`;
    
    if (width && height) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      imageUrl = imageUrl + `?dimensions=${props?.width}x${props?.height}`;
    }
    return {
      props: {
        width,
        height,
        imageUrl,
      },
    };
  },
  render: ({ imageUrl, width, height, imageOptions }) => {
    function isValidUrl(url: string) {
      try {
        // eslint-disable-next-line no-new
        new URL(url);
        return true;
      } catch (e) {
        return false;
      }
    }
    
    

    return (
      <Section>
        <div className="flex justify-center text-3xl w-full align-center text-center pt-10 mb-6">
          {isValidUrl(imageUrl) ? (
            <img
              src={`${imageUrl || ""}`}
              //@ts-ignore
              crossorigin="anonymous"
              alt={imageOptions?.alt || ""}
              width={width || 250}
              height={height || 250}
            ></img>
          ) : (
            <div>Image not found</div>
          )}
        </div>
      </Section>
    );
  },
});
