import React from "react";
import { type ComponentConfig } from "@measured/puck";
import styles from "./styles.module.css";
import getClassNameFactory from "@/lib/getClassNameFactory";
import * as reactFeather from "react-feather";

const getClassName = getClassNameFactory("Card", styles);

const icons = Object.keys(reactFeather).reduce((acc, iconName) => {
  if (typeof reactFeather[iconName] === "object") {
    const El = reactFeather[iconName];

    return {
      ...acc,
      [iconName]: <El />,
    };
  }

  return acc;
}, {});

const iconOptions = Object.keys(reactFeather).map((iconName) => ({
  label: iconName,
  value: iconName,
}));

export interface CardProps {
  title: string;
  description: string;
  icon?: "Feather";
  mode: "flat" | "card";
  backgroundColor?: string;
  textColor?: string;
  borderRadius?: string;
  borderWidth?: string;
  borderColor?: string;
  padding?: string;
}

export const Card: ComponentConfig<CardProps> = {
  fields: {
    title: { type: "text" },
    description: { type: "textarea" },
    icon: {
      type: "select",
      options: iconOptions,
    },
    mode: {
      type: "radio",
      options: [
        { label: "card", value: "card" },
        { label: "flat", value: "flat" },
      ],
    },
    backgroundColor: { type: "text" },
    textColor: { type: "text" },
    borderRadius: { type: "text" },
    borderWidth: { type: "text" },
    borderColor: { type: "text" },
    padding: { type: "text" },
  },
  defaultProps: {
    title: "Title",
    description: "Description",
    icon: "Feather",
    mode: "flat",
    backgroundColor: "#ffffff", // Default white background
    textColor: "#000000", // Default black text
    borderRadius: "4px", // Default radius
    borderWidth: "1px", // Default border width
    borderColor: "#cccccc", // Default border color
    padding: "16px", // Default
  },
  render: ({
    title,
    icon,
    description,
    mode,
    backgroundColor,
    textColor,
    borderRadius,
    borderWidth,
    borderColor,
    padding,
  }) => {
    return (
      <div
        className={getClassName({ [mode]: mode })}
        style={{
          backgroundColor,
          color: textColor,
          borderRadius,
          borderWidth,
          borderColor,
          padding,
          // Apply new style properties
        }}
      >
        <div className={getClassName("icon")}>
          {icons[icon as keyof typeof icons]}
        </div>
        <div className={getClassName("title")}>{title}</div>
        <div className={getClassName("description")}>{description}</div>
      </div>
    );
  },
};
