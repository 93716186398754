import { type Config, type Data } from "@measured/puck";
import { create } from "zustand";
import Cookies from "js-cookie";
import { isTokenExpired } from "./tokenCheck";

export interface ISheet {
  id: string;
  name: string;
}

export interface ICell {
  id: number;
  toolId: number;
  sheet: string;
  defaultValue: number;
  cell: string;
  lookupValues: string;
  type: string;
  label: string;
  namedRange: string;
  dataType: string;
}


export interface ITool {
  hideFooter: any;
  summary: {
    toolsAllowed: number;
    toolsUsed: number;
    toolsLeft: number;
  };
  inputs?: ICell[];
  outputs?: ICell[];
  ouputs?: ICell[];
  publicDomain: string;
  id: string;
  uuid: string;
  published: boolean;
  name: string;
  filename: string;
  spreadsheet: string | null;
  sheets: ISheet[];
  isSelected?: boolean;
  isCollaborator?: boolean;
  isOwner?: boolean;
  file?: string;
  favicon?: string;
  v?: number;
}
export interface ICurrentPlan {
  costPerMonth: number;
  id: string;
  numberOfTools: number;
  planName: string;
}
interface IUser {
  jwt: string;
  email: string;
  firstname: string;
  currentPlan: ICurrentPlan;
  surname: string;
}

export interface ICollaborator {
  collaboratorId: number;
  userId: number;
  firstname: string;
  surname: string;
  email: string;
  phone: string;
}

interface IStore {
  selectedTool?: ITool;
  currentPlan?: ICurrentPlan;
  user?: IUser;
  collaborators: ICollaborator[];
  isDialogOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  registerEmail?: string;
  puckData?: Data;
  token?: null | string;
  selectedImage?: { imageUrl: string; alt?: string };
  puckConfig?: Config;
}

export const useStore = create<IStore>((set, get) => {
  const token = isTokenExpired(Cookies.get("token") || "")
    ? null
    : Cookies.get("token");
  const userCookie = Cookies.get("user");
  const user = userCookie ? JSON.parse(userCookie) : undefined;
  const currentPlanCookie = Cookies.get("currentPlan");
  const currentPlan = currentPlanCookie
    ? JSON.parse(currentPlanCookie)
    : undefined;

  return {
    selectedTool: undefined,
    token,
    currentPlan,
    user,
    puckData: {
      content: [],
      root: {},
    },
    selectedImage: undefined,
    puckConfig: undefined,
    registerEmail: undefined,
    collaborators: [],
    isDialogOpen: false,
    openDialog: () => set({ isDialogOpen: true }),
    closeDialog: () => set({ isDialogOpen: false }),
  };
});

useStore.subscribe(
  (store, previousState) => {
    if (store.token !== previousState.token) {
      Cookies.set("token", store.token || "");
    }

    if (store.user !== previousState.user) {
      const userString = store.user ? JSON.stringify(store.user) : "";
      Cookies.set("user", userString);
    }
    if (store.currentPlan !== previousState.currentPlan) {
      const currentPlanString = store.currentPlan
        ? JSON.stringify(store.currentPlan)
        : "";
      Cookies.set("currentPlan", currentPlanString);
    }
  },
  // @ts-expect-error
  (state) => ({
    token: state.token,
    user: state.user,
    currentPlan: state.currentPlan,
  })
);
