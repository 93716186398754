"use client";
import { type ReactNode } from "react";

import { type DefaultRootProps } from "@measured/puck";
import { Footer } from "../components/Footer";

export type RootProps = {
  children: ReactNode;
  title: string;
} & DefaultRootProps;

const NavItem = ({ label, href }: { label: string; href: string }) => {
  const navPath = window?.location.pathname.replace("/edit", "") || "/";

  const isActive = navPath === (href.replace("/edit", "") || "/");

  return (
    <a
      href={href || "/"}
      style={{
        textDecoration: "none",
        color: isActive
          ? "var(--puck-color-grey-1)"
          : "var(--puck-color-grey-5)",
        fontWeight: isActive ? "600" : "400",
      }}
    >
      {label}
    </a>
  );
};

function Root({ children, editMode, background }: RootProps) {
  return (
    <>
      {/* <header>

          <div
          className="flex justify-center text-3xl w-100 align-center text-center mt-10 mb-6"
          >
            LOGO
          </div>
      </header> */}
      <div style={{ background, width: "100%" }}>
        <div className="container pb-16"> {children}</div>
      </div>
    </>
  );
}

export default Root;
