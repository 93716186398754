import { allowedDuplicateComponents } from "@/config";

import useToolData from "@/lib/useTool";
import { useEffect, useRef, useState } from "react";

import { getData } from "./fetchDataClient";
import { ICell, useStore } from "./store";
export const useCombinedToolData = (id: string, isViewer?: boolean) => {
  const tool = useToolData(id, isViewer);
  const puckData = useStore((state) => state.puckData);

  const currentCellsRef = useRef([]);
  const [availableInputCells, setAvailableInputCells] = useState<
    ICell[] | undefined
  >(undefined);
  const [availableOutputCells, setAvailableOutputCells] = useState<
    ICell[] | undefined
  >(undefined);
  const [initialLoading, setInitLoading] = useState(true);
  //@ts-ignore
  const usedLabels = new Set(currentCellsRef.current.map((item) => item.type));
  const filteredCells = (cells: ICell[] | undefined) =>
    cells?.filter(
      (cell) =>
        !usedLabels.has(cell.label) ||
        allowedDuplicateComponents.includes(cell.label)
    );
  const setDataTmp = async (newData: any) => {
    if (JSON.stringify(newData) !== JSON.stringify(puckData)) {
      if (newData?.content?.length > 0) {
        useStore.setState({ puckData: newData });
      }
    }
  };
  useEffect(() => {
    if (
      tool &&
      (!availableInputCells || availableInputCells.length === 0) &&
      (!availableOutputCells || availableOutputCells.length === 0)
    ) {
      setAvailableInputCells(
        tool?.inputs?.map((i) => ({ ...i, inputOutput: "input" }))
      );
      console.log("🚀 ~ useEffect ~ tool:", tool);
      setAvailableOutputCells(
        tool?.ouputs?.map((i) => ({ ...i, inputOutput: "output" }))
      );
    }
  }, [tool, availableInputCells, availableOutputCells]);

  useEffect(() => {
    let isActive = true;
    const fetchDataAsync = async () => {
      let result;
        result = await getData(`/tools/view/${id}`);

        
        
        
      try {
        if (isActive) {
          let pd = result && result.tool.designerData;

          if (pd) {
            useStore.setState({ puckData: pd });
            //Save data here

            setInitLoading(false);
          }
          if (initialLoading) setInitLoading(false);
        }
      } catch (error) {
        useStore.setState({ puckData: undefined });
        setInitLoading(false);
        console.error(error);
      }
    };

    if (id) {
      fetchDataAsync();
    }

    return () => {
      isActive = false;
      setInitLoading(true);
      useStore.setState({ puckData: undefined });
    };
  }, [id, tool]);

  return {
    tool,
    puckData,
    availableInputCells: filteredCells(availableInputCells),
    availableOutputCells: filteredCells(availableOutputCells),
    setData: setDataTmp,
    initialLoading,
  };
};
