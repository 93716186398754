import { useState } from "react";

import { Button } from "@/components/ui/button";
function isValidUrl(url: string) {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}
export function ImageSelect({
  images,
  onChange,
  value,
}: {
  images: Array<{ id: number; image: string; thumbnail: string; alt: string }>;
  onChange: (imageObj: {
    id: number;
    image: string;
    thumbnail: string;
    alt: string;
  }) => void;
  value: any;
}) {
  const [selectedImage, setSelectedImage] = useState<{
    id: number;
    image: string;
    thumbnail: string;
    alt: string;
  } | null>(value || null);

  const [error, setError] = useState<string | null>(null);

  const handleImageSelect = (imageObj: {
    id: number;
    image: string;
    thumbnail: string;
    alt: string;
  }) => {
    const imageUrl = `${process.env.REACT_APP_BASE_URL}${imageObj.thumbnail}`;
    if (isValidUrl(imageUrl)) {
      setSelectedImage(imageObj);
      setError(null);
    } else {
      setError("Invalid image URL");
    }
  };

  const handleConfirm = () => {
    if (selectedImage) {
      onChange(selectedImage);
    }
  };

  return (
    <div className="grid grid-cols-6 mt-5">
      {images.map((imageObj) => {
        const imageUrl = `${process.env.REACT_APP_BASE_URL}${imageObj.thumbnail}`;
        return (
          <div
            key={imageObj.id}
            className={`cursor-pointer overflow-hidden p-2 w-16 ${
              selectedImage?.id === imageObj.id
                ? "w-1 ring-1 ring-black rounded "
                : ""
            }`}
            onClick={() => {
              handleImageSelect(imageObj);
            }}
          >
            {/* <Image
              src={imageUrl}
              width={75}
              height={75}
              alt={imageObj.alt}
              className="object-cover"
            /> */}
          </div>
        );
      })}
    </div>
  );
}
