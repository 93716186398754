import React, { useState } from "react";
import { ComponentConfig } from "@measured/puck";
import styles from "./styles.module.css";
import getClassNameFactory from "@/lib/getClassNameFactory";
import { Button } from "@/components/ui/button";
import { Section } from "../../../components/Section";
import { NextLink } from "@/components/ui/Link";

const getClassName = getClassNameFactory("Hero", styles);

export type HeroProps = {
  title: string;
  description: string;
  align?: string;
  padding: string;
  titleColor: string;
  descriptionColor: string;
  borderColor?: string;
  borderRadius?: string;
  borderWidth?: string;
  margin?: string;
  imageMode?: "inline" | "background";
  imageUrl?: string;
  buttons: {
    label: string;
    href: string;
    textColor: string;
    backgroundColor: string;
    variant?: "primary" | "secondary";
    more?: { text: string }[];
  }[];
};

export const Hero: ComponentConfig<HeroProps> = {
  fields: {
    title: { type: "text" },
    titleColor: { type: "text" },
    description: { type: "textarea" },
    descriptionColor: { type: "text" },
    buttons: {
      type: "array",
      getItemSummary: (item) => item.label || "Button",
      arrayFields: {
        label: { type: "text" },
        href: { type: "text" },
        textColor: { type: "text", label: "Text Color" },
        backgroundColor: { type: "text", label: "Background Color" },
      },
    },
    align: {
      type: "radio",
      options: [
        { label: "left", value: "left" },
        { label: "center", value: "center" },
      ],
    },
    imageUrl: { type: "text" },
    imageMode: {
      type: "radio",
      options: [
        { label: "inline", value: "inline" },
        { label: "background", value: "background" },
      ],
    },
    padding: { type: "text" },

    borderColor: { type: "text" },
    borderRadius: { type: "text" },
    borderWidth: { type: "text" },
    margin: { type: "text" },
  },
  defaultProps: {
    title: "Hero",
    align: "left",
    titleColor: "#000",
    descriptionColor: "#000",

    borderColor: "transparent",
    borderRadius: "0",
    borderWidth: "0",
    margin: "0",
    description: "Description",
    //@ts-ignore
    buttons: [{ label: "Learn more", href: "#" }],
    padding: "64px",
  },

  render: ({
    align,
    title,
    titleColor,
    description,
    descriptionColor,
    buttons,
    padding,
    imageUrl,
    imageMode,
    borderColor,
    borderRadius,
    borderWidth,
    margin,
  }) => {
    // Empty state allows us to test that components support hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [_] = useState(0);


    return (
      <Section
        padding={padding}
        className={`${getClassName({
          left: align === "left",
          center: align === "center",
          hasImageBackground: imageMode === "background",
        })} bg-inherit !bg-none	`}
        style={{
          borderColor,
          borderRadius,
          borderWidth,
          margin,
        }}
      >
        {imageMode === "background" && (
          <>
            <div
              className={getClassName("image")}
              style={
                {
                  // backgroundImage: `url("${imageUrl}")`,
                }
              }
            ></div>

            <div className={getClassName("imageOverlay")}></div>
          </>
        )}

        <div className={`${getClassName("inner")}`}>
          <div className={getClassName("content")}>
            <h1 //@ts-ignore
              style={{ color: titleColor, textAlign: align || "left" }}
            >
              {title}
            </h1>
            <p //@ts-ignore
              style={{ color: descriptionColor, textAlign: align || "left" }}
              className={getClassName("subtitle")}
            >
              {description}
            </p>
            <div className={getClassName("actions")}>
              {buttons.map((button, i) => (
                <NextLink
                  key={i}
                  href={button.href}
                  style={{
                    color: button.textColor,
                    background: button.backgroundColor,
                  }}
                >
                  {button.label}
                </NextLink>
              ))}
            </div>
          </div>

          {align !== "center" && imageMode !== "background" && imageUrl && (
            <div
              style={{
                backgroundImage: `url('${imageUrl}')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderRadius: 24,
                height: 356,
                marginLeft: "auto",
                width: "100%",
              }}
            />
          )}
        </div>
      </Section>
    );
  },
};
