import React from 'react'

import { type ComponentConfig } from '@measured/puck'
import { Section } from '../../../components/Section'
import { Heading as _Heading } from '../../../components/Heading'

export interface HeadingProps {
  align: 'left' | 'center' | 'right'
  text?: string
  level?: any
  size: any
  padding?: string
  color?: string
  margin?: string
  fontWeight?: string
  borderBottom?: string
}

const sizeOptions = [
  { value: 'xxxl', label: 'XXXL' },
  { value: 'xxl', label: 'XXL' },
  { value: 'xl', label: 'XL' },
  { value: 'l', label: 'L' },
  { value: 'm', label: 'M' },
  { value: 's', label: 'S' },
  { value: 'xs', label: 'XS' }
]

const levelOptions = [
  { label: '', value: '' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' }
]

export const Heading: ComponentConfig<HeadingProps> = {
  fields: {
    text: { type: 'text' },
    size: {
      type: 'select',
      options: sizeOptions
    },
    level: {
      type: 'select',
      options: levelOptions
    },
    align: {
      type: 'radio',
      options: [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' }
      ]
    },
    padding: { type: 'text' },
    color: { type: 'text' },
    margin: { type: 'text' },
    fontWeight: { type: 'text' },
    borderBottom: { type: 'text' }
  },
  defaultProps: {
    align: 'left',
    text: 'Heading',
    padding: '24px',
    size: 'm',
    color: '#000000', // Default text color
    margin: '0', // Default margin
    fontWeight: 'normal', // Default font weight
    borderBottom: 'none'
  },
  render: ({
    align,
    text,
    size,
    level,
    padding,
    color,
    margin,
    fontWeight,
    borderBottom
  }) => {
    return (
      <Section padding={padding}>
        <_Heading size={size} rank={level}>
          <span
            style={{
              display: 'block',
              textAlign: align,
              width: '100%',
              color,
              margin,
              fontWeight,
              borderBottom
            }}
          >
            {text}
          </span>
        </_Heading>
      </Section>
    )
  }
}
