import React from 'react'
import { type ComponentConfig, DropZone } from '@measured/puck'
import { Section } from '../../../components/Section'

export interface CaclulatorBlockProps {
  align: 'left' | 'center' | 'right'
  backgroundColor: string
  borderRadius: string
}

export const CaclulatorBlock: ComponentConfig<CaclulatorBlockProps> = {
  fields: {
    align: {
      type: 'radio',
      options: [
        { label: 'left', value: 'left' },
        { label: 'center', value: 'center' },
        { label: 'right', value: 'right' }
      ]
    },
    backgroundColor: {
      type: 'text' // Using text type for backgroundColor
    },
    borderRadius: {
      type: 'text' // Using text type for borderRadius
    }
  },
  defaultProps: {
    align: 'left',
    backgroundColor: 'white', // Default white background
    borderRadius: 'none' // Default no border radius
  },
  render: ({ align, backgroundColor, borderRadius }) => {
    const alignmentClasses = {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right'
    }
    return (
      <Section>
        <div
          className={`w-[24em] mb-10 p-8 flex flex-col ${alignmentClasses[align]}`}
          style={{
            backgroundColor,
            borderRadius
          }}
        >
          <div className="calculator-block" key={'idx'}>
            <DropZone zone={'column'} />
          </div>
        </div>
      </Section>
    )
  }
}
