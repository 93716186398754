import { type CSSProperties, type ReactNode } from 'react'
import styles from './styles.module.css'
import getClassNameFactory from '@/lib/getClassNameFactory'

const getClassName = getClassNameFactory('Section', styles)

export interface SectionProps {
  className?: string
  children: ReactNode
  padding?: string
  maxWidth?: string
  style?: CSSProperties
}

export const Section = ({
  children,
  className,
  padding = '0px',
  maxWidth = '1280px',
  style = {}
}: SectionProps) => {
  return (
    <div
      className={`${getClassName()}${className ? ` ${className}` : ''}`}
      style={{
        ...style,
        paddingTop: padding,
        paddingBottom: padding
      }}
    >
      <div className={getClassName('inner')} style={{ maxWidth }}>
        {children}
      </div>
    </div>
  )
}
