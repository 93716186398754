"use client";

import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";
import { cn } from "@/lib/utils";

interface SliderProps
  extends React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> {
  trackColor?: string;
  rangeColor?: string;
  thumbColor?: string;
  borderColor?: string;
}

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  SliderProps
>(
  (
    {
      className,
      trackColor = "#000",
      rangeColor = "#000",
      thumbColor = "#fff",
      borderColor = "#000",
      ...props
    },
    ref
  ) => {
    return (
      <SliderPrimitive.Root
        ref={ref}
        className={cn(
          "relative flex w-full touch-none select-none items-center mt-3",
          className
        )}
        {...props}
      >
        <SliderPrimitive.Track
          className="relative h-1 w-full grow overflow-hidden rounded-full"
          style={{ backgroundColor: trackColor }}
        >
          <SliderPrimitive.Range
            className="absolute h-full"
            style={{ backgroundColor: rangeColor }}
          />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
          className="block h-5 w-5 rounded-full border-2"
          style={{
            backgroundColor: thumbColor,
            borderColor: borderColor,
          }}
        />
      </SliderPrimitive.Root>
    );
  }
);
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
