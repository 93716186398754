/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable multiline-ternary */
import React from "react";
import { type ComponentConfig } from "@measured/puck";
import { Section } from "@/components/Section";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";

export interface InputFormSubmitProps {
  label: string;
  loading: boolean;
}

export const customInputFormConfig = ({
  loading,
}: {
  loading: boolean;
}): ComponentConfig => ({
  fields: {
    label: { type: "text" },
    textColor: { type: "text", label: "Text Color" },
    backgroundColor: { type: "text", label: "Background Color" },
    loading: {
      type: "radio",
      options: [
        { label: "true", value: true },
        { label: "false", value: false },
      ],
    },
    align: {
      type: "radio",
      options: [
        { label: "Left", value: "left" },
        { label: "Center", value: "center" },
        { label: "Right", value: "right" },
      ],
    },
  },
  defaultProps: {
    label: "Submit",
    textColor: "#fff",
    backgroundColor: "#000",
    align: "left",
    loading: false,
  },
  render: ({ label, align, textColor, backgroundColor }) => {
    let alignClass = "justify-start";
    if (align === "center") {
      alignClass = "justify-center";
    } else if (align === "right") {
      alignClass = "justify-end";
    }
    return (
      <Section className="self-center mt-6 pb-6">
        <div className={`flex ${alignClass}`}>
          {loading ? (
            <Button
              type="submit"
              disabled
              style={{ color: textColor, background: backgroundColor }}
            >
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Loading
            </Button>
          ) : (
            <Button
              style={{ color: textColor, background: backgroundColor }}
              type="submit"
            >
              {label || "Submit"}
            </Button>
          )}
        </div>
      </Section>
    );
  },
});
